var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app","data-app":""}},[_c('div',{staticClass:"row col-xl-12 mx-0 px-0"},[_c('Topform',{ref:"diary-topform",attrs:{"managementType":_vm.managementType,"status":_vm.calculatedStatus,"pageType":"diary","name":_vm.activityInfo.title,"errors":_vm.activityErrors,"submissionStates":_vm.submissionStates,"parentPage":"/project/activities/view","tabs":_vm.computedTabs,"validation":_vm.computedValidation,"loadingComplete":_vm.loadingComplete,"additionalActions":_vm.computedActions},on:{"clear_errors":function($event){return _vm.clearErrors()},"asset_reservation_created":function($event){return _vm.form.stimuli.push($event)},"asset_reservation_deleted":function($event){_vm.form.stimuli = _vm.form.stimuli.filter(function (stim) {
          return stim !== $event;
        })},"remove_project_asset":function($event){return _vm.form.stimuli_remove.push($event)},"start_date_updated":function($event){_vm.form.starts_on = $event},"end_date_updated":function($event){_vm.form.ends_on = $event},"initial_notification_period_changed":_vm.initialNotificationPeriodChanged,"notification_repeat_option_changed":_vm.notificationRepeatOptionChanged,"repeat_notification_period_changed":_vm.repeatNotificationPeriodChanged,"clear_existing_data":_vm.clearData,"submit":_vm.onSubmit,"submit_then_sequence":_vm.onSubmitThenSequence}}),_c('div',{staticClass:"col-xl-4"},[_c('div',{staticClass:"card card-custom gutter-b"},[_c('div',{staticClass:"card-body"},[(_vm.loadingComplete == false)?_c('b-spinner',{staticClass:"card-loader",attrs:{"variant":"primary","label":"Spinning"}}):_vm._e(),(_vm.loadingComplete && !_vm.error.active)?_c('div',[_c('h6',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("ACTIVITY.MANAGE.HELPER_SEGMENT_TITLE"))+" ")]),_c('p',{staticClass:"mb-10"},[_vm._v(" "+_vm._s(_vm.$t("ACTIVITY.MANAGE.HELPER_SEGMENT_TEXT"))+" ")]),_c('div',{staticClass:"row"},[_c('InputAdvancedSegmentSelect',{staticClass:"px-5 mb-5",attrs:{"id":"input-segments","submissionStates":{ submitting: false },"children_key":"project_segments","label_key":"name","options":_vm.projectSegmentations,"initialSelection":_vm.initial_selection,"sequenced":_vm.isSequencedActivity ||
                  (_vm.isEditActivity && _vm.activityInfo.sequence_parent != null)},on:{"children_changed":function($event){_vm.form.segments = $event},"clear_segments":function($event){_vm.form.segments = []},"initial_selection_changed":function($event){_vm.initial_selection = $event}}})],1)]):_vm._e()],1)])]),_c('div',{staticClass:"col-xl-8 pl-0 pr-2"},[_c('b-modal',{ref:"sequence-modal",attrs:{"size":"xl","hide-footer":"","title":_vm.$t('TABLES.ACTIVITY_MESSAGES.SEQUENCE_MODAL')}},[_c('div',{staticClass:"btn-group btn-group-sm w-100",staticStyle:{"box-shadow":"none !important"},attrs:{"id":"activity_sequence_selector","role":"group"}},[_vm._l((_vm.systemActivityTypes),function(systemActivityType,i){return [(systemActivityType.can_sequence)?_c('a',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],key:i,staticClass:"btn btn-outline-secondary bg-white px-3 py-5",staticStyle:{"border":"none !important"},attrs:{"href":"javascript:void(0)","title":_vm.$t('NAVIGATION.ACTIVITY_DESCRIPTION.' + ([8,10].includes(systemActivityType.id) ? 8 : systemActivityType.id))},on:{"click":function($event){_vm.onSubmit(
                  'add_' +
                    systemActivityType.name.toLowerCase().split(' ').join('_')
                )}}},[_c('i',{staticClass:"text-muted px-0 mb-3",class:_vm.getSystemActivityTypeIcon(systemActivityType)}),_c('p',{staticClass:"font-size-sm mb-0"},[_vm._v(" "+_vm._s(_vm.$t("NAVIGATION.ADD_ACTIVITY." + ([8,10].includes(systemActivityType.id) ? 8 : systemActivityType.id)))+" ")])]):_vm._e()]})],2)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }